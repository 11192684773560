.rodo {
  position: relative;
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  cursor: pointer;

  &__checkbox {
    display: block!important;
    width: 0!important;
    height: 0!important;
     -moz-appearance: none;

    &:checked {
      & ~ .rodo__checkmark {
        border-color: $primary-color;
        transition: all .25s ease;

        &:after {
          background: $primary-color;
        }
      }
    }

    &.error {
      &~.rodo__checkmark {
        border-color: $red-color;
      }

      &~.rodo__text {
        color: $red-color;
      }
    }
  }

  &__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background: #F9F9F9;
    border: 1px solid #D1D1DB;
    border-radius: 3px;
    transition: all .25s ease;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      margin: 1px;
      height: 11px;
      width: 11px;
      border-radius: 2px;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.2px;
  }
}