.modal-rodo {
  width: 100%;
  padding: 0;
  display: table;
  height: 100%;
  position: fixed;
  z-index: 16000002;
  top: 0;
  right: 0;
  left: 0;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: visibility .4s, opacity .4s ease;

  &.active {
    visibility: visible;
    opacity: 1;
  }

  &__inner {
    padding: 0;
    vertical-align: middle;
    display: table-cell;
    margin: 0;
  }

  &__wrapper {
    background: $white-color;
    width: 540px;
    height: auto;
    margin: 0 auto;
    position: relative;
    min-height: 300px;
    padding: 25px 30px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    box-sizing: border-box;

    @include respond-to('phone') {
      width: 95vw;
      padding: 15px;
    }

    &__header {
      font-size: 18px;
      line-height: 25px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    &__content {
      max-height: 300px;
      box-sizing: border-box;
      overflow-y: auto;
      padding: 0 10px 0 0;
      margin-bottom: 20px;
      transition: .25s ease;
      @include scrollbar(8px, $white-color, #323233, $black-color);

      a {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        text-decoration: none;
        color: $primary-color;
      }
    }

    &__btns {
      display: flex;
      flex-direction: row;

      @include respond-to('phone') {
        flex-direction: column;
      }
    }
  }

  &__content {
    &__list {
      list-style: decimal inside;

      &__item {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 8px;

        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          display: block;
          margin-left: 8px;
        }
      }
    }
  }

  &__btn {
    width: 50%;

    @include respond-to('phone') {
      width: 100%;
    }

    &--transparent {
      border: 1px solid #323233;
      margin-right: 10px;

      @include respond-to('phone') {
        margin: 0 0 10px;
      }
    }
  }
}