.download-section {
  display: grid;
  grid-template-columns: 480px auto;
  grid-template-rows: 430px;
  background-color: #222222;
  grid-template-areas: 'form image';

  @include respond-to('hd') {
    grid-template-columns: 640px auto;
    grid-template-rows: 570px;
  }

  @include respond-to('tablet') {
    grid-template-columns: 360px auto;
  }

  @include respond-to('phone') {
    grid-template-columns: 100%;
    grid-template-rows: 220px 390px;
    grid-template-areas: 'image' 'form';
  }

  &__form {
    position: relative;
    margin: 40px 60px 60px;
    grid-area: form;

    @include respond-to('hd') {
      margin: 60px 120px 100px;
    }

    @include respond-to('tablet') {
      margin: 30px 40px 50px;
    }

    @include respond-to('phone') {
      margin: 30px 40px 50px;
    }

    &__title {
      color: #e7ad5d;
      font-size: 12px;
      font-weight: 600;
      line-height: 30px;
      text-transform: uppercase;
      padding-bottom: 13px;

      @include respond-to('hd') {
        font-size: 14px;
      }

      @include respond-to('phone') {
        padding-bottom: 6px;
      }
    }

    &__text {
      color: #ffffff;
      font-size: 25px;
      font-weight: 600;
      letter-spacing: -0.59px;
      line-height: 35px;

      @include respond-to('hd') {
        font-size: 32px;
        font-weight: 600;
        letter-spacing: -0.75px;
        line-height: 45px;
      }

      @include respond-to('tablet') {
        font-size: 25px;
        letter-spacing: -0.52px;
        line-height: 35px;
      }

      @include respond-to('phone') {
        font-size: 22px;
        letter-spacing: -0.52px;
        line-height: 30px;
      }
    }

    &__container {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      &__label {
        display: block;
        width: 100%;
        color: #e2e2e2;
        font-size: 14px;
        font-weight: 400;
        line-height: 25px;

        @include respond-to('phone') {
          font-size: 12px;
          line-height: 25px;
        }
      }

      &__input {
        display: block;
        width: 100%;
        padding: 10px;
        border-radius: 2px;
        background-color: transparent;
        color: #ffffff;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: -0.12px;
        line-height: 25px;
        border: 1px solid #e2e2e2;
        margin-bottom: 20px;

        &:focus {
          outline: none;
        }

        &::-webkit-input-placeholder {
          color: #ffffff;
          font-size: 15px;
          font-weight: 400;
        }
      }

      &__button {
        display: block;
        width: 100%;
        padding: 10px;
        border-radius: 2px;
        background-color: #e7ad5d;
        color: #000000;
        font-size: 12px;
        font-weight: 600;
        line-height: 30px;
        text-transform: uppercase;
        border: 1px solid #e7ad5d;
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        opacity: 1;

        @include respond-to('hd') {
          font-size: 14px;
        }
      }
    }
  }

  &__image {
    grid-area: image;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
