.case-other {
  //margin-top: 100px;
  transition: all .5s ease-in-out;
  column-gap: 30px;
  column-fill: initial;
  column-count: 2;
  padding-bottom: 80px;
  margin-top: 80px;

  @include respond-to('hd') {
    column-gap: 100px;
  }
  @include respond-to('phone') {
    column-count: 1;
    margin-top: 40px;
    padding-bottom: 10px;
  }

  @include respond-to('fullhd') {
    margin-top: 50px;
  }

  a {
    color: #000;
    &.case-other__box__service {
      color: inherit;
      display: initial;
    }
  }

  &__box {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 30px;
    position: relative;

    @include respond-to('hd') {
      margin-bottom: 100px;
    }
    @include respond-to('phone') {
      margin-left: -15px;
      margin-top: 15px;
      margin-bottom: 15px;
      width: calc(100% + 30px) !important;
    }

    &:hover {
      .case-other__box__img img {
        &:nth-child(2) {
          opacity: 1;
        }
      }

      .case-other__box__txt {
        border: 1px solid #E2E2E2;
        background: #fff;
      }
    }

    @include respond-to('phone') {
      width: 100%;
      margin-top: 15px;

      &:first-of-type {
        margin-top: 0;
      }
    }

    &:nth-child(n+1) {
      margin-right: 12px;
    }

    &__video {
      position: relative;
      left: 0;
      top: 0;
      width: 100%;
    }

    &__container {
      overflow: hidden;
      line-height: 0;

      &--small {
        background-size: cover;
        width: 570px;
        height: 270px;

        @include respond-to('phone') {
          width: 100%;
          height: 270px;
        }
      }

      &--big {
        width: 570px;
        height: 570px;
        @include respond-to('phone') {
          width: 100%;
          height: 270px;
        }
      }
    }
    &__img {
      transition: all 0.6s;
      display: block;
      height: 100%;
      width: 100%;
      position: relative;

      img {
        height: 100%;
        width: 100%;
        display: block;
        &:nth-child(2) {
          position: absolute;
          top: 0;
          width: 100%;
          left: 0;
          opacity: 0;
          transition: opacity 0.8s;
        }
      }

      &--small {
        width: 570px;
        height: 285px;
        @include respond-to('phone') {
          width: 100%;
          height: 270px;
        }
      }

      &--big {
        width: 570px;
        height: 570px;
        @include respond-to('phone') {
          width: 100%;
          height: 270px;
        }
      }
    }
    &__txt {
      padding: 40px 60px 60px 40px;
      width: 100%;
      background-color: #f7f7f7;
      height: auto;
      position: relative;
      border: 1px solid #f7f7f7;
      transition: all 0.8s;

      @include respond-to('phone') {
        height: auto;
        padding: 30px 40px 40px 30px;
      }

      &__topic {
        font-size: 25px;
        font-weight: 600;
        letter-spacing: -1px;
        line-height: 35px;
        text-transform: none;
        color: #000;
        width: 100%;

        @include respond-to('fullhd') {
          font-size: 30px;
          line-height: 40px;
        }
      }

      &__category {
        margin-top: 30px;
        @include style-element('blog_info');
        color: #000;
        text-transform: uppercase;
      }

      &__description {
        color: rgba(39, 41, 42, 0.8);
        @include style-element('p');
        margin-top: 15px;
      }

      &:hover {
        .case-other__box__img img {
          &:nth-child(2) {
            opacity: 1;
          }
        }

      }
    }
  }

}

.case-more {
  width: 570px;
  height: 70px;
  border: 1px solid #e4a044;
  background-color: #ffffff;
  display: block;

  @include respond-to('phone') {
    width: 100%;
    margin-top: 50px;
  }

  .action-arrow {
    text-align: center;
    padding-top: 20px;
  }
}
