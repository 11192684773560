.top-minimal {
  position: relative;
  width: 100%;
  color: $black-color;
  padding: 192px 0 100px;

  @include respond-to('fullhd') {
    padding: 268px 0 160px;
  }

  @include respond-to('tablet') {
    padding: 158px 0 100px;
  }

  @include respond-to('phone') {
    padding: 124px 0 112px;
  }

  &__container {
    position: relative;
    max-width: 780px;
    margin-left: 0;

    @include respond-to('fullhd') {
      max-width: 892px;
    }

    @include respond-to('tablet') {
      max-width: 565px;
    }

    @include respond-to('phone') {
      width: 100%;
    }
  }

  &__h1 {
    @include style-element('h1');
  }

  &__text {
    color: $black-color;
    margin-top: 30px;
    @include style-element('p');

    @include respond-to('phone') {
      margin-top: 20px;
    }
  }

  .btn-cta {
    margin-top: 88px;

    @include respond-to('phone') {
      margin-top: 45px;
    }
  }
}
