.simple-text-boxes {
  width: 100%;
  display: grid;
  vertical-align: top;
  font-size: 0;

  .container {
    padding: 0 50px !important;
    margin: auto !important;
    width: auto !important;

    @include respond-to('hd') {
      padding: 0 150px !important;
      width: auto !important;
    }
    @include respond-to('tablet') {
      width: 100% !important;
      padding-left: 30px !important;
      padding-right: 30px !important;

    }
    @include respond-to('phone') {
      width: 100% !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
    & > a.simple-text-box {
      border: 1px solid #e2e2e2;
      margin: 0 -1px -1px 0;
    }
  }

}

.simple-text-box {
  width: 33%;
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  height: 280px;
  border-radius: 0;
  padding: 25px;
  color: #000;
  border-collapse: collapse;

  @include respond-to('tablet') {
    width: 50%;
  }

  &:hover {
    background: #E2E2E2;
  }

  @include respond-to('phone') {
    height: auto;
    border: 1px solid #e2e2e2 !important;
  }

  &__topic {
    font-size: 19px;
    font-weight: 400;
    letter-spacing: -0.16px;
    line-height: 26px;
    color: #000;
    height: 30px;
    @include respond-to('phone') {
      height: auto;
    }
  }

  &__price {
    font-size: 19px;
    font-weight: 300;
    line-height: 35px;
    width: 100%;
    height: 35px;
    @include respond-to('phone') {
      height: auto;
    }

  }

  &__lead {
    margin-top: 24px;
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    height: 75px;

    @include respond-to('phone') {
      height: auto;
    }
  }

  .action-arrow {
    margin-top: 45px;
  }
}
