/* Inter */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/Inter-Thin.woff2') format('truetype'), /* Super Modern Browsers */
  url('../fonts/Inter-Thin.woff') format('truetype'), /* Modern Browsers */
  url('../fonts/Inter-Thin.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/Inter-Light.woff2') format('truetype'), /* Super Modern Browsers */
  url('../fonts/Inter-Light.woff') format('truetype'), /* Modern Browsers */
  url('../fonts/Inter-Light.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Inter-Regular.woff2') format('truetype'), /* Super Modern Browsers */
  url('../fonts/Inter-Regular.woff') format('truetype'), /* Modern Browsers */
  url('../fonts/Inter-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/Inter-Medium.woff2') format('truetype'), /* Super Modern Browsers */
  url('../fonts/Inter-Medium.woff') format('truetype'), /* Modern Browsers */
  url('../fonts/Inter-Medium.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/Inter-SemiBold.woff2') format('truetype'), /* Super Modern Browsers */
  url('../fonts/Inter-SemiBold.woff') format('truetype'), /* Modern Browsers */
  url('../fonts/Inter-SemiBold.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/Inter-ExtraBold.woff2') format('truetype'), /* Super Modern Browsers */
  url('../fonts/Inter-ExtraBold.woff') format('truetype'), /* Modern Browsers */
  url('../fonts/Inter-ExtraBold.ttf') format('truetype'); /* Safari, Android, iOS */
}