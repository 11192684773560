.minimal-top {
  position: relative;
  width: 100%;
  color: $black-color;

  &--h2 {
    @include style-element('h2');
  }

  &--p {
    color: $black-color;
    margin-top: 30px;
    @include style-element('p');

    @include respond-to('phone') {
      margin-top: 20px;
    }
  }

  .btn-cta {
    height: fit-content;
    min-width: 100px;
    padding: 12px 28px;
    font-weight:800;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.8px;

    @include respond-to('phone') {
      margin-top: 45px;
    }
  }

  // narazie nie znalazlem tego uzycia
  //video {
  //  @include respond-to('phone') {
  //    display: none;
  //  }
  //}

  .container {
    display: flex;
    justify-content: space-between;

    @include respond-to('phone') {
      flex-direction: column;
    }
  }

  .container--main {
    position: relative;
    margin-left: 0;
    max-width: 685px;

    @include respond-to('fullhd') {
      max-width: 765px;
    }

    @include respond-to('tablet') {
      max-width: 565px;
    }

    @include respond-to('phone') {
      width: 100%;
    }
  }
}
