.service-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-gap: 25px;

  @include respond-to('fullhd') {
    grid-gap: 125px 160px;
  }

  @include respond-to('tablet') {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px 70px;
  }

  @include respond-to('phone') {
    grid-auto-flow: column;
    grid-column-gap: 50px;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .service-list__element {
    display: flex;
    flex-direction: column;
    padding: 20px;
    transition: .25s ease;

    &:hover {
      transition: .25s ease;
    }

    &__title {
      margin-bottom: 14px;

      h3 {
        @include style-element('h3');
        transition: .25s ease;

        @include respond-to('tablet') {
          font-size: 24px !important;
          line-height: 35px !important;
        }
      }
    }

    &__text {
      position: relative;
      margin-bottom: 40px;

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 23px;
        transition: .25s ease;

        @include respond-to('fullhd') {
          font-size: 18px;
          line-height: 25px;
        }

        @include respond-to('phone') {
          font-size: 15px;
        }
      }

      @include respond-to('phone') {
        margin-bottom: 30px;
      }
    }

    &__link {
      text-decoration: none;
      color: unset;
      cursor: pointer;
    }

    &__img {
      display: flex;
      align-items: flex-end;
    }

    &__icon {
      max-width: 100%;
      height: auto;
    }

    &__arrow {
      position: absolute;
      bottom: -40px;
      cursor: pointer;

      @include respond-to('phone') {
        bottom: -30px;
      }
    }
  }

  &--columns-image {
    grid-template-columns: repeat(2, 1fr);

    @include respond-to('fullhd') {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 25px;
    }

    @include respond-to('phone') {
      grid-auto-flow: unset;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 25px;
    }

    .service-list__element {
      padding: 0;
      border-radius: 3px;

      @include respond-to('phone') {
        display: block;
        width: 100%;
      }

      &__title {
        margin: 45px 45px 0;

        h3 {
          font-size: 28px !important;
          line-height: 35px !important;

          @include respond-to('tablet') {
            font-size: 24px !important;
          }
        }

        @include respond-to('fullhd') {
          margin: 60px 60px 0;
        }

        @include respond-to('phone') {
          margin: 25px 25px 0;
        }
      }

      &__text {
        margin: 0 45px 45px;

        p {
          font-size: 18px;
          line-height: 28px;

          @include respond-to('tablet') {
            font-size: 16px;
            line-height: 26px;
          }
        }

        @include respond-to('fullhd') {
          margin: 0 60px 60px;
        }

        @include respond-to('phone') {
          margin: 0 25px 25px;
        }
      }
    }
  }

  &--columns-icon {
    grid-template-columns: repeat(2, 1fr);

    @include respond-to('fullhd') {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 25px;
    }

    @include respond-to('phone') {
      grid-auto-flow: unset;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 25px;
    }

    .service-list__element {
      padding: 60px;
      border-radius: 3px;

      &__text p {
        font-size: 18px;
        line-height: 28px;
      }

      &__title {
        h3 {
          font-size: 28px !important;
          line-height: 35px !important;
        }
      }

      @include respond-to('tablet') {
        padding: 25px;
      }

      @include respond-to('phone') {
        display: block;
        width: 100%;
        padding: 25px;
      }

      &__img {
        margin-bottom: 30px;
      }
    }
  }

  &--boxes {
    @include respond-to('phone') {
      display: none;
    }

    .service-list__element__img {
      margin-bottom: 24px;
    }
  }

  &--boxes-mobile {
    display: none;

    @include respond-to('phone') {
      display: block;
      width: 100%;

      .swiper-container {
        width: 100%;
        height: 100%;
        overflow: hidden;
      }

      .swiper-wrapper {
        display: inline-flex;
      }

      .swiper-slide {
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
      }

      .swiper-pagination {
        bottom: 30px !important;
      }

      .swiper-pagination-bullet {
        background: $grey-darker2-color;
        opacity: .5;
      }

      .swiper-pagination-bullet-active {
        background: $primary-color;
        opacity: .8;
      }

      .service-list__element {
        padding: 30px 30px 60px;

        &__img {
          margin-bottom: 24px;
        }
      }
    }
  }
}