.script-top {
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  top: 0;
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;

  @include respond-to('phone') {
    background-position: bottom;

    &--left {
      background-position: bottom;
      background-size: contain;
    }
  }

  .container {
    padding: 0 180px !important;
    margin: auto !important;
    width: auto !important;

    @include respond-to('tablet') {
      width: 100% !important;
      padding-left: 30px !important;
      padding-right: 30px !important;

    }
    @include respond-to('phone') {
      width: 100% !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: 1s all;
  }

  &__box {
    position: relative;
    width: 415px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    vertical-align: top;
    height: 100vh;
    align-content: center;
    flex-wrap: wrap;


    @include respond-to('fullhd') {
      width: 640px;
    }

    @include respond-to('phone') {
      width: 100%;
      margin-top: -70px;
    }

    &__topic {
      @include style-element('h1');
      position: relative;
      width: 100%;
      z-index: 3;
      color: $black-color !important;

      @include respond-to('phone') {
        word-break: break-word !important;
      }

      @include respond-to('fullhd') {
        font-size: 72px !important;
        line-height: 73px !important;
        letter-spacing: -1px !important;
      }
    }

    &__type {
      font-weight: 400;
      font-size: 24px;
      line-height: 35px;
      letter-spacing: -0.2px;
      color: $black-color;
      margin-top: 20px;

      @include respond-to('fullhd') {
        margin-top: 30px;
        font-weight: 400;
        font-size: 24px;
        line-height: 35px;
      }

      @include respond-to('tablet') {
        font-size: 18px;
        line-height: 30px;
      }

      @include respond-to('phone') {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
      }
    }

    .btn-cta {
      margin-top: 86px;

      @include respond-to('tablet') {
        margin-top: 40px;
      }

      @include respond-to('phone') {
        margin-top: 45px;
      }

    }
  }

  &__video {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;

    @include respond-to('phone') {
      display: none;
    }

    &--mobile {
      display: none;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -1;

      @include respond-to('phone') {
        display: block;
      }
    }
  }

  // do sprawdzenia
  .webgl {
    canvas {
      position: absolute;
      right: 0;
      top: 0;
      width: 100% !important;
      height: 100%;
      z-index: 1;
    }
  }
}


