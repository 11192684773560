.info-cta {
  position: relative;

  @include respond-to('phone') {
    padding: 0 !important;

    .container {
      padding: 0;
    }
  }

  &__bg {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 50%;

    &--up {
      top: 0;
    }

    &--down {
      top: 50%;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: auto 260px;
    grid-gap: 50px;
    background: $primary-color;
    padding: 55px 78px;
    border-radius: 6px;

    @include respond-to('fullhd') {
      padding: 90px 180px;
    }

    @include respond-to('tablet') {
      padding: 62px 50px;
    }

    @include respond-to('phone') {
      grid-template-columns: 1fr;
      padding: 50px 30px;
      border-radius: 0;
    }

    &__left {
      max-width: 650px;

      @include respond-to('tablet') {
        max-width: 440px;
      }
    }

    &__right {
      display: flex;
      align-items: center;
    }
  }

  &__title {
    @include style-element('h2');
    font-size: 36px !important;
    line-height: 44px !important;
    color: $white-color;
  }

  &__text {
    @include style-element('p');
    color: $white-color;
    margin-top: 26px;

    @include respond-to('fullhd') {
      margin-top: 36px;
    }
  }

  &__btn {
    width: 100%;

    @include respond-to('fullhd') {
      font-size: 24px;
      line-height: 30px;
      padding: 25px;
    }

    @include respond-to('phone') {
      width: auto;
    }
  }
}