.contact-feedback {
  display: none;

  &__image {
    display: block;
    width: 95px;
    height: 95px;
    object-fit: contain;
    margin-bottom: 40px;

    @include respond-to('fullhd') {
      width: 120px;
      height: 120px;
      margin-bottom: 60px;
    }

    @include respond-to('tablet') {
      width: 90px;
      height: 90px;
    }
    @include respond-to('phone') {
      width: 75px;
      height: 75px;
      margin-bottom: 30px;
    }
  }

  &__title {
    @include style-element('h2');
    margin-bottom: 20px;

    @include respond-to('fullhd') {
      margin-bottom: 30px;
    }
  }

  &__text {
    @include style-element('p')
  }
}