.fullpage {
  position: relative;
  width: 100%;
  overflow: hidden;

  &__background {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: .3;
  }

  &__section {
    width: 100%;
    height: 100%;

    &__image {
      display: block;
      width: 100%;
      height: auto;
    }

    &__video {
      display: block;
      width: 100%;
      height: auto;
    }

    &--mobile {
      @include respond-to('phone') {
        height: 100%;
      }
    }
  }
}