.contact {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @include respond-to('phone') {
    grid-template-columns: 1fr;
  }

  &__left,
  &__right {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 30px;

    &__box {
      max-width: 450px;
      height: 100%;
    }
  }

  &__form,
  &__wyswig {
    height: 100%;
  }

  &__right__box {
    display: flex;
    flex-direction: column;
  }

  &__title {
    @include style-element('h2');
  }

  &__text {
    @include style-element('p');
    margin-top: 30px;
  }

  &__form {
    margin-top: 65px;

    &__label {
      display: block;
      margin-top: 45px;
      font-weight: 800;
      font-size: 28px;
      line-height: 35px;
      letter-spacing: -0.5px;

      &:first-of-type {
        margin-top: 0
      }

      &--rodo {
        margin-top: 70px !important;
      }

      input {
        display: block;
        width: 100%;
        margin-top: 10px;
        padding: 10px 0;
        border-width: 0 0 1px 0;
        border-style: solid;
        border-color: $black-darker-color;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        outline: none;
      }

      textarea {
        display: block;
        width: 100%;
        margin-top: 10px;
        padding: 10px 0;
        border-width: 0 0 1px 0;
        border-style: solid;
        border-color: $black-darker-color;
        font-family: $font-Inter;
        font-size: 18px;
        line-height: 28px;
        outline: none;

        &::placeholder {
          font-family: $font-Inter;
          font-size: 18px;
          line-height: 28px;
        }
      }

      & > *.error {
        border-color: $red-color;
      }
    }

    &__btn {
      border: none;
      margin-top: 46px;
    }
  }

  form {
    display: flex;
    flex-direction: column;
  }

  .details__body {
    &__content {
      h2 {
        @include style-element('h2');
        margin-bottom: 30px;
      }

      h3 {
        @include style-element('h3');
        font-weight: 800;
        text-transform: none;
        margin-bottom: 30px;

        @include respond-to('phone') {
          font-size: 38px;
          line-height: 50px;
        }
      }

      h4 {
        @include style-element('h4');
        font-weight: 800;
        text-transform: none;
        margin-bottom: 30px;
      }

      p {
        @include style-element('p');
        margin: 30px auto;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}