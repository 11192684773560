.case {
  width: 100%;
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-attachment: scroll;
  position: relative;

  @include respond-to('phone') {
    height: auto;
    //min-height: 85vh;
    background-size: inherit;
  }
  @include respond-to('tablet') {
    height: auto;
    min-height: 500px;
  }

  .container {
    padding: 0 50px !important;
    margin: auto !important;
    width: auto !important;

    @include respond-to('hd') {
      padding: 0 180px !important;
      width: auto !important;
    }
    @include respond-to('tablet') {
      width: 100% !important;
      padding-left: 30px !important;
      padding-right: 30px !important;

    }
    @include respond-to('phone') {
      width: 100% !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
  }

  &__mask {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    @include respond-to('phone') {
      position: relative;
      height: auto;
      display: inherit;
      font-size: 0;
      min-height: auto;
    }

    @include respond-to('tablet') {
      position: absolute;
      height: auto;
    }

  }

  &__video {
    left: 0;
    bottom: 0;
    top: 0;
    position: absolute;
    height: auto !important;
    width: 100% !important;

    @include respond-to('phone') {
      position: absolute;
      height: auto;
      width: 100%;
    }

    @include respond-to('tablet') {
      position: relative;
      height: auto;
      width: 100%;
    }
  }

  &__background {
    width: 440px;
    margin-left: -50px;
    height: auto;
    background: rgb(255, 255, 255);
    position: absolute;
    bottom: 150px;
    padding-left: 50px;
    padding-bottom: 50px;
    padding-top: 50px;
    padding-right: 50px;

    @include respond-to('phone') {
      width: calc(100% + 60px);
      height: auto;
      padding-left: 30px;
      padding-right: 30px;
      left: -30px;
      position: relative;
      bottom: 0;
      margin-left: 0;
      background: #FCF9F4;

    }

    @include respond-to('tablet') {
      width: 100%;
      height: auto;
      margin-left: 0;
      padding-left: 30px;
      padding-right: 30px;
      left: 0;
      position: absolute;
      bottom: 0;

    }

    &__topic {
      font-size: 25px;
      font-weight: 800;
      line-height: 35px;
      letter-spacing: -1px;
      color: #21211f;

      @include respond-to('fullhd') {
        font-size: 30px;
        line-height: 40px;
      }
      @include respond-to('phone_tablet') {
        font-size: 30px;
        line-height: 40px;
      }
    }

    &__category {
      color: #21211F;
      @include style-element('blog_info');
      margin-top: 20px;

      a {
        color: #21211F;
      }
    }

    &__description {
      color: #21211F;
      @include style-element('p');
      margin-top: 15px;
    }

    &__view {
      margin-top: 30px;
    }
  }
}
